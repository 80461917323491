import React, { useState, useRef } from 'react'
// import { navigate } from 'gatsby'
import { validateEmail, validateEmpty } from '../../utils/validation'
import PaypalDonateButton from './PaypalDonateButton'

const DonateForm = props => {

	const [name, setName] = useState('')
	const [nameIsValid, setNameIsValid] = useState(true)
	const [amount, setAmount] = useState(props.amount || '25.00')
	const [amountIsValid, setAmountIsValid] = useState(true)
	const [email, setEmail] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(true)
	const [giftAid, setGiftAid] = useState(false)
	const [addressHouse, setAddressHouse] = useState('')
	const [addressHouseIsValid, setAddressHouseIsValid] = useState(true)
	const [addressLine1, setAddressLine1] = useState('')
	const [addressLine1IsValid, setAddressLine1IsValid] = useState(true)
	const [addressPostcode, setAddressPostcode] = useState('')
	const [addressPostcodeIsValid, setAddressPostcodeIsValid] = useState(true)
	const [captcha, setCaptcha] = useState('')
	const [captchaIsValid, setCaptchaIsValid] = useState(true)

	const [formIsValid, setFormIsValid] = useState(false)
	const [showError, setShowError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)

	const formName = 'boswell-book-festival-donate'
	const submitUrl = '/donate'

	const paypalDonateButtonRef = useRef()

	const handleAmountInputChange = event => {
		setAmount(event.target.value)
		setAmountIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleNameInputChange = event => {
		setName(event.target.value)
		setNameIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleEmailInputChange = event => {
		setEmail(event.target.value)
		setEmailIsValid(validateEmail(event.target.value))
		handleFormValidation()
	}

	const handleGiftAidInputChange = event => {
		setGiftAid(event.target.checked)
		// setting formIsValid here based on the checkbox checked because running handleFormValidation()
		// does not observe the correct setting for giftAid, instead it appears to run with its previous value
		if (event.target.checked) {
			setFormIsValid(
				validateEmpty(name) &&
				validateEmail(email) &&
				!validateEmpty(captcha) &&
				validateEmpty(addressHouse) &&
				validateEmpty(addressLine1) &&
				validateEmpty(addressPostcode)
			)
		} else {
			setFormIsValid(
				validateEmpty(name) &&
				validateEmail(email) &&
				!validateEmpty(captcha)
			)
		}
	}

	const handleCaptchaInputChange = event => {
		setCaptcha(event.target.value)
		setCaptchaIsValid(!validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleFormValidation = () => {
		if (giftAid) {
			setFormIsValid(
				validateEmpty(name) &&
				validateEmail(email) &&
				!validateEmpty(captcha) &&
				validateEmpty(addressHouse) &&
				validateEmpty(addressLine1) &&
				validateEmpty(addressPostcode)
			)
		} else {
			setFormIsValid(
				validateEmpty(name) &&
				validateEmail(email) &&
				!validateEmpty(captcha)
			)
		}
	}

	const handleAddressHouseInputChange = event => {
		setAddressHouse(event.target.value)
		setAddressHouseIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleAddressLine1InputChange = event => {
		setAddressLine1(event.target.value)
		setAddressLine1IsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}

	const handleAddressPostcodeInputChange = event => {
		setAddressPostcode(event.target.value.toUpperCase())
		setAddressPostcodeIsValid(validateEmpty(event.target.value))
		handleFormValidation()
	}


	const handleSubmit = event => {

		if (formIsValid) {
			event.target.setAttribute('disabled', true)
			submitForm().then(
				event.target.removeAttribute('disabled')
			)
		}
	}

	const encodeForm = (data) => {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	const submitForm = async (event) => {
		// submits netlify form via fetch
		setShowError(false)
		setShowSuccess(false)

		const data = {
			name: name,
			amount: amount,
			email: email,
			giftaid: giftAid,
			address_house: addressHouse,
			address_line1: addressLine1,
			address_postcode: addressPostcode,
			form_origin: props.title
		}

		await fetch(submitUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: encodeForm({'form-name': formName, ...data}),
		})
			.then(() => {
				handleSubmitPaypalDonate()
				setShowSuccess(true)
			})
			.catch(error => {
				handleSubmitPaypalDonate()
				setShowError(true)
			})
	}

	const handleSubmitPaypalDonate = () => {
		paypalDonateButtonRef.current && paypalDonateButtonRef.current.submit()
	}

	const labelStyles = `text-bbf-dark-green`
	const invalidLabelStyles = `text-bbf-red`
	const inputStyles = `transition-all w-full py-2 px-4 text-xl text-bbf-dark-green shadow-inner border-2 border-bbf-dark-green focus:outline-none focus:ring-4 focus:ring-bbf-mid-green focus:ring-opacity-50`
	const invalidInputStyles = `text-bbf-red border-bbf-red`
	const validationMessageStyles = `transition text-sm text-bbf-red mb-2 opacity-0`

	return (
		<div className="">
			<h2 className={`text-4xl mt-1 mb-8`}>Donate</h2>
			{ props.title && props.title !== 'Donate form' ? (
				<p className={`mb-4 text-lg`}>{props.title}</p>
			) : '' }
			<form
				action="/donate-thank-you"
				method="POST"
				name={formName}
				className=""
				data-netlify={true}
				data-netlify-honeypot="captcha"
			>
				<input type="hidden" name="form-name" value={formName} />
				<input type="hidden" name="form_origin" value={props.title} />

				<label htmlFor="amount" className={`flex flex-col items-start`}>
					<p className={`${labelStyles} ${!amountIsValid && invalidLabelStyles}`}>Amount (£)</p>
					<input
						type="number"
						name="amount"
						id="amount"
						value={amount}
						placeholder={props.amountPlaceholderText || '25.00'}
						className={`${inputStyles} ${!amountIsValid && invalidInputStyles}`}
						onChange={handleAmountInputChange}
						onKeyUp={handleAmountInputChange}
						required
					/>
				</label>
				<p className={`${validationMessageStyles} ${!amountIsValid && 'opacity-100'}`}>
					Please enter a donation amount
				</p>

				<label htmlFor="name" className={`flex flex-col items-start`}>
					<p className={`${labelStyles} ${!nameIsValid && invalidLabelStyles}`}>Full name</p>
					<input
						type="text"
						name="name"
						id="name"
						value={name}
						placeholder={props.namePlaceholderText || 'Avery Kind-Person'}
						className={`${inputStyles} ${!nameIsValid && invalidInputStyles}`}
						onChange={handleNameInputChange}
						onKeyUp={handleNameInputChange}
						required
					/>
				</label>
				<p className={`${validationMessageStyles} ${!nameIsValid && 'opacity-100'}`}>
					Please enter your full name
				</p>

				<label htmlFor="email" className={`flex flex-col items-start`}>
					<p className={`${labelStyles} ${!emailIsValid && invalidLabelStyles}`}>Email address</p>
					<input
						type="email"
						name="email"
						id="email"
						value={email}
						placeholder={props.emailPlaceholderText || 'avery.kind-person@helping.us'}
						className={`${inputStyles} ${!emailIsValid && invalidInputStyles}`}
						onChange={handleEmailInputChange}
						onKeyUp={handleEmailInputChange}
						required
					/>
				</label>
				<p className={`${validationMessageStyles} ${!emailIsValid && 'opacity-100'}`}>
					Please enter a valid email address
				</p>

				<p><strong>Please Gift Aid your donation.</strong></p>
				<p className={`mb-2`}>For every £1 you donate The Boswell Trust can reclaim an additional 25p from HMRC.<br/>Your donation will be worth £{(amount * 1.25).toFixed(2)} with Gift Aid.</p>
				<label htmlFor="giftaid" className={`${labelStyles} flex flex-row items-center mb-4`}>
					<input
						type="checkbox"
						name="giftaid"
						id="giftaid"
						checked={giftAid}
						onChange={handleGiftAidInputChange}
					/>
					<p className={`${labelStyles} font-bold ml-2`}>I want to Gift Aid my donation to The Boswell Trust.</p>
				</label>

				{/* Gift Aid Form */}
				<div className={giftAid ? '' : 'hidden'}>
					<p className={`mb-2`}>Gift Aid is reclaimed by the charity from the tax you pay for the current tax year. Your address is
						needed to identify you as a current UK taxpayer. </p>
					<label htmlFor="address_house" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!addressHouseIsValid && invalidLabelStyles}`}>House number/name</p>
						<input
							type="text"
							name="address_house"
							id="address_house"
							value={addressHouse}
							placeholder={props.namePlaceholderText || 'Auchendrane'}
							className={`${inputStyles} ${!addressHouseIsValid && invalidInputStyles}`}
							onChange={handleAddressHouseInputChange}
							onKeyUp={handleAddressHouseInputChange}
							required
						/>
					</label>
					<p className={`${validationMessageStyles} ${!addressHouseIsValid && 'opacity-100'}`}>
						Please enter your house number or name
					</p>

					<label htmlFor="address_line1" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!addressLine1IsValid && invalidLabelStyles}`}>Address</p>
						<input
							type="text"
							name="address_line1"
							id="address_line1"
							value={addressLine1}
							placeholder={props.namePlaceholderText || 'By Ayr'}
							className={`${inputStyles} ${!addressLine1IsValid && invalidInputStyles}`}
							onChange={handleAddressLine1InputChange}
							onKeyUp={handleAddressLine1InputChange}
						/>
					</label>
					<p className={`${validationMessageStyles} ${!addressLine1IsValid && 'opacity-100'}`}>
						Please enter your address
					</p>

					<label htmlFor="address_postcode" className={`flex flex-col items-start`}>
						<p className={`${labelStyles} ${!addressPostcodeIsValid && invalidLabelStyles}`}>Postcode</p>
						<input
							type="text"
							name="address_postcode"
							id="address_postcode"
							value={addressPostcode}
							placeholder={props.namePlaceholderText || 'KA7 4TW'}
							className={`${inputStyles} ${!addressPostcodeIsValid && invalidInputStyles}`}
							onChange={handleAddressPostcodeInputChange}
							onKeyUp={handleAddressPostcodeInputChange}
							required
						/>
					</label>
					<p className={`${validationMessageStyles} ${!addressPostcodeIsValid && 'opacity-100'}`}>
						Please enter your postcode
					</p>

					<p className={`mb-4`}>I am a UK taxpayer and understand that if I pay less Income Tax and/or Capital Gains Tax than
						the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any
						difference.</p>
				</div>

				<label className={`flex flex-col items-start hidden`}>
					<p className={`${labelStyles} ${!captchaIsValid && invalidLabelStyles}`}>Captcha</p>
					<input
						type="text"
						name="captcha"
						value={captcha}
						placeholder={
							props.captchaPlaceholderText ||
							"if you can see this, don't fill it in!"
						}
						className={`${inputStyles} ${!captchaIsValid && invalidInputStyles}`}
						onChange={handleCaptchaInputChange}
						onKeyUp={handleCaptchaInputChange}
					/>
					<p className={`${validationMessageStyles} ${!captchaIsValid && 'opacity-100'}`}>
						Please do NOT fill this in
					</p>
				</label>

				{showError && (
					<p className="mb-4 py-2 px-4 text-white bg-bbf-red font-semibold">
						There has been a problem submitting your message. Please try again.
					</p>
				)}

				{showSuccess && (
					<p className="mb-4 py-2 px-4 text-white bg-bbf-mid-green-alt font-semibold">
						Thank you! Redirecting to PayPal to complete your donation ...
					</p>
				)}

				<button
					className={`transition-all w-full text-white ${
						formIsValid ? 'bg-bbf-teal' : 'bg-gray-400 cursor-not-allowed'
					} py-3 px-6 uppercase shadow transition-all focus:outline-none focus:ring-4 focus:ring-bbf-teal focus:ring-opacity-50`}
					type="button"
					disabled={!formIsValid}
					onClick={handleSubmit}
				>
					{props.submitButtonText ? props.submitButtonText : 'Donate'} £{amount}
				</button>
			</form>

			<div className={`hidden`}>
				<PaypalDonateButton ref={paypalDonateButtonRef} amount={amount} title={props.title || `Donate form`}/>
			</div>
		</div>
	)
}

export default DonateForm
